.separator {
  position: relative;
  text-align: center;
  margin: 3em 0;

  .separator-text-wrapper {
    position: absolute;
    top: -0.75em;
    text-align: center;
    width: 100%;

    .separator-text {
      display: inline-block;
      margin: auto;
      padding: 0 6px;
      background-color: white
    }
  }
}

.thirdparty {
  .button {
    width: 100%;
  }
}

a.sign-up-alternative {
  color: #666;
  &:hover, &:focus {
    color: black;
  }
}

.stickies {
  margin-top: 24px;
}

.is-max-half {
  max-width: 440px;
}

